.input-form .col-form-label{
    font-weight: normal;
}

.form-error {
    color: red;
    font-style: italic;
}


.form-error {
    text-align: right;
    display: inline;
    padding: 0px 2px;
}