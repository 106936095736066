/*
HomePage-template.scss
*/

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.cdnfonts.com/css/avenir-next-lt-pro');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url("https://fonts.cdnfonts.com/css/futura-std-2");
@import url("https://fonts.cdnfonts.com/css/anton");
@import url("https://fonts.cdnfonts.com/css/cormorant-garamond");
$fa-font-path: './fontawesome/webfonts';
@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";


$bleu_fonce_THCC: #090426;
$bleu_clair_THCC: #CED5EB;
$gris_medium_THCC: #f5f5f5;
$gris_fonce_THCC:darkgray;
$gris_clair_THCC:#fafafa;
$or_THCC: #B3A357;
$red_error_THCC:rgb(255,64,64);
$text_color_on_button_THCC:#f2e3c1;

$popins-font-family: 'Poppins', sans-serif;
$futura-light-font-family: 'Futura Std', sans-serif;
$anton-font-family: 'Anton', sans-serif;
$cormorant-garamont-light-font-family: 'Cormorant Garamond', sans-serif;

$container-background-color: $bleu_clair_THCC;
$welcome-font-color: $bleu_fonce_THCC;

$current-fontfamily : $futura-light-font-family;
$title-font-family: $anton-font-family;
$menu-font-family: $popins-font-family;
$label-font-family: $popins-font-family;
$input-font-family:$cormorant-garamont-light-font-family;

                                                
$avenir-next-lt-pro-family: 'Avenir Next LT Pro', sans-serif;


body {
    font-family: $current-fontfamily;
    font-size: larger;
    background-color:$or_THCC!important;
    color:$bleu_fonce_THCC;
    padding:0px;
    margin:0px;
}

h1, h2, h3, h4, h5 {
    font-family: $title-font-family;
    color: $bleu_fonce_THCC!important;
}

.link-with-arrow{
    @extend .h3;
    @extend %fa-icon;
    @extend .fas;
    color:$welcome-font-color;
    font-weight: bold;

    &::before{
        content:fa-content($fa-var-arrow-right);
        margin-right: 5px;
    }
}

.pointer{
    cursor: pointer;
}

.pic{
    @extend .pointer;
    width:150px;
}

#partner-form-container{
    text-align: left;
    background-color: $bleu_clair_THCC;

    label{
        font-family: $label-font-family;
        color: $bleu_fonce_THCC;
        font-size: small;
    }

    .pic{
        width:150px;
        cursor: pointer;
    }

    ._2dBhC._3TyBu ._1SOvY:invalid {
        border-width: var(--brwe,1px);
        background-color: rgba(var(--bge,255,255,255),var(--alpha-bge,1));
        border-style: solid;
        border-color: rgba(var(--brde,163,217,246),var(--alpha-brde,1));
    }

    .Ch2LG._1UP8Q {
        color: rgb(var(--txt_placeholder,136,136,136));
        border-color: var(--corvid-border-color,rgba(var(--brd,248,248,248),var(--alpha-brd,1)));
    }
    .cyPU8 .Ch2LG {
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: var(--inputHeight);
        border-width: var(--corvid-border-width,var(--brw,2px));
        border-color: rgba(var(--brd,248,248,248),var(--alpha-brd,1));
    }
}

.panel-default {
    color: red;
    text-align: center;
    border: cornflowerblue 2px solid;
    font-weight: bold;
}

.partners-columns-container{
    @extend .row;
    @extend .align-items-end;
    @extend .container-spacing;
    box-sizing: border-box;
    
    .partner-item{
        @extend .col-sm-12;
        @extend .col-md-6;
        @extend .col-lg-4;
        
        box-sizing: border-box;
        margin-top:10px;
    }
}

.container-spacing{
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.page-container-template{
    @extend .container;
    max-width: 1024px;
    margin: auto;
}


.page-title-and-introduction{
    .page-title{
        margin: 20px auto;
        @extend h3;
        text-align:center;
        font-weight: bolder;
    }

    .page-introduction{
        @extend .container;        
        @extend h4;
        color: $welcome-font-color;
        margin: 40px auto;
        text-align: justify;
    }
}

.add-partner-page,
.admin-partners-page,
.browse-partners-page,
.contact-us-page
{
    @extend .page-container-template;
    @extend .page-title-and-introduction;
}

.admin-partners-page{
    padding-top: 100px;
}

.main-page-container {
    .top-banner-image{    
        div.hover-title{
            position: relative;
            bottom:100px;
            z-index: 500;
            width: 100%;
            text-align: center;
            font-size: 2rem;
            font-weight: 900;
            color: #0088ff;
            font-family: "Montserrat";
        }
    }

    .body-page-container{
        .welcome-title{
            div:first-child {
                @extend h3;
                color: $welcome-font-color;
                text-align:center;
                font-weight: bolder;
                margin-top: 20px;
            }
        }

        .premium-and-top-news-container{
            @extend .container;
            @extend .container-spacing;
            display: flex;

            /*color Debugging
            @media screen and (max-width: 967px) {
                background-color: red;
            }

            @media screen and (max-width: 767px) {
                background-color: green!important;
            }

            @media screen and (max-width: 479px) {
                background-color: #0088ff!important;
            }*/

            .premium-container{
                text-align: center;
                flex-basis: 70%;
                background-color: $bleu_fonce_THCC;
                padding: 10px;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;

                .premium-teaser{
                    padding-top: 10px;
                    @extend h4;
                    text-align:left;
                    color:$bleu_clair_THCC!important;
                }
            }

            .top-news-container{
                padding:10px;
                flex-basis: 30%;
                background-color: $bleu_clair_THCC;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
                margin-left: 10px;
                overflow-y: hidden;

                @media screen and (max-width: 967px) {
                    flex-basis: 50%!important;
                }

                @media screen and (max-width: 767px) {
                    flex-basis: 70%!important;
                }
                
                @media screen and (max-width: 479px) {
                    flex-basis: 100%!important;
                }

                .news-columns-container{
                   padding: 10px;
                    .news-column{
                        @extend .col-sm;

                        .latest-news-teaser{
                            @extend h4;
                            text-align:left;
                            color:$welcome-font-color;
                        }

                    }
                }
            }
        }        

        .partners-fluid-container{
            width: 100%;                
            background-color: $bleu_fonce_THCC;
            text-align: center;
            .partners-container{
                @extend .container;
                @extend .container-spacing;
                width: 100%;
                color: $bleu_clair_THCC;
                
                text-align: center;

                .partners-title {
                    @extend h3;
                    color:$welcome-font-color;
                }

                .partners-introduction{
                    text-align: center;
                }
            }
        } 
        
        .categories-fluid-container{
            @extend .container-fluid;
            @extend .container-spacing;

            .categories-container{
                @extend .container;
                @extend .container-spacing;

                .categories-title{
                    @extend h3;
                    text-align: center;
                    color: $welcome-font-color;
                }

                .categories-grid-cells{
                    @extend .container-spacing;
                    @extend .container;
                    @extend .row;
                    text-align: center;

                    .category-cell{
                        @extend .col-sm-3;
                    }
                }
            }
        }
    }
}

.who-page-container {
    .top-banner-image{    
        div.hover-title{
            position: relative;
            bottom:100px;
            z-index: 500;
            width: 100%;
            text-align: center;
            font-size: 2rem;
            font-weight: 900;
            color: #0088ff;
            font-family: "Montserrat";
        }
    }

    .body-page-container{
        .welcome-title{
            div:first-child {
                @extend h3;
                color: $welcome-font-color;
                text-align:center;
                font-weight: bolder;
                margin-top: 20px;
            }
        }

        .introduction-container{
            @extend .container;
            @extend .container-spacing;

            .thcc-marketplace-intro{
                text-align: center;
                margin-bottom: 50px;
            }

            .introduction-columns-container{
                @extend .row;

                .introduction-column{
                    @extend .col-sm;
                    text-align: justify;
                    .intro-title {
                        @extend h5;
                    }
                }
            }
        }        

        .partners-fluid-container{
            width: 100%;                
            background-color: $bleu_fonce_THCC;
            text-align: center;
            .partners-container{
                @extend .container;
                @extend .container-spacing;
                width: 100%;
                color:$bleu_clair_THCC;
                text-align: center;

                .partners-title {
                    @extend h3;
                    color:$welcome-font-color;
                }

                .partners-introduction{
                    text-align: center;
                }
            }
        } 
        
        .news-fluid-container{
            @extend .container-fluid;
            .news-container{
                @extend .container;
                @extend .container-spacing;
                .news-columns-container{
                    @extend .row;
                    @extend .align-top;

                    .news-column{
                        @extend .col-sm;

                        .latest-news-teaser{
                            @extend h4;
                            text-align:left;
                            color:$welcome-font-color;
                        }

                    }
                }
            }
        }

        .categories-fluid-container{
            @extend .container-fluid;
            @extend .container-spacing;
            background-color: $bleu_fonce_THCC;
            color: $or_THCC!important;

            .categories-container{
                @extend .container;
                @extend .container-spacing;

                .categories-title{
                    @extend h3;
                    color: $or_THCC!important;
                    text-align: center;
                }

                .categories-grid-cells{
                    @extend .container-spacing;
                    @extend .container;
                    @extend .row;
                    text-align: center;
                    color: $or_THCC!important;

                    .category-cell{
                        @extend .col-sm-3;
                    }
                }
            }
        }
    }
}

.browse-partners-page{
    width: 100%;                
    text-align: center;
    .partners-container{
        @extend .container;
        @extend .container-spacing;
        width: 100%;
        text-align: center;

        .partners-title {
            @extend h3;
            color:$welcome-font-color;
        }

        .partners-introduction{
            text-align: center;
        }

        .no-result{
            @extend h2;
            background-color: #1d242a;
            color:skyblue;
        }
    }
}


.detail-partner-fluid-container{
    @extend .container-fluid;
    background-color: $bleu_clair_THCC;
    text-align: center;
    padding: 0px!important;/*disable padding in fluid container*/

    .title-section{
        @extend .h3;
        font-variant: small-caps; 
        padding-top: 30px;
        padding-bottom: 20px;

        &::before{
            content: "";
            position: absolute;
            width: calc(100% - 20px);
            height: 2px;
            background: black;
            left: 10px;
            margin: auto;
        }
    }

    .partner-detail-container{
        @extend .container-spacing;
        @extend .page-container-template;
        width: 100%;

        .partner-contact{
            @extend .row;
            width: 100%;
            margin: 20px auto;
            display: flex;

            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            .contact-items-template{
                @extend .btn-primary;
                @extend .col-md-4;
                @extend .pointer;
                padding: 20px;
                font-size: 15px;
                font-family: $popins-font-family;
           }

            .contact-phone,
            .contact-email,
            .contact-website{
                @extend .contact-items-template;
                //max-width: calc(33% - 20px)!important;
                @media not screen and (max-width: 767px) {
                    max-width: calc(33% - 20px)!important;
                }

                @media screen and (max-width: 767px) {
                    width: 100%!important;
                }
    
                min-width: 200px;
                margin-top: 5px;
            }
/*
            .contact-phone{
                @extend %fa-icon;
                @extend .fas;
                @extend .contact-items-template;

                &::before{
                    content:fa-content($fa-var-phone);
                    margin-right: 5px;
                }
            }

            .contact-email{
                @extend .contact-items-template;

                &::before{
                    content:fa-content($fa-var-mail-bulk);
                    margin-right: 5px;
                }
            }

            .contact-website{
                @extend .contact-items-template;

                &::before{
                    content:fa-content($fa-var-wifi);
                    margin-right: 5px;
                }
            }
*/
            .contact-share{
                @extend .contact-items-template;

                &::before{
                    content:fa-content($fa-var-arrow-right);
                    margin-right: 5px;
                }
            }
        }

        .partner-media-container{
            @extend .row;

            .media-video{
                @extend .col-md-4;
                height: 250px;
                margin-bottom: 10px;;
           }

           .next-event-trailer{
            background-color: black;
            padding: 10px;

                .player-legend{
                    color: #1d242a;
                    font-style: italic;
                }
            }

            .media-description{
                @extend .col-md-8;
                max-height: 250px;
                overflow: auto;
                text-align:justify;

                p{
                    margin: 0px!important;
                    padding: 0px!important;
                }
            }
        }

        .partner-gallery{
            @extend .row;
            width: 100%;
            padding:20px;

            .gallery-item{
                @extend .col-sm-12;
                @extend .col-md-6;
                @extend .col-lg-4;
                margin: 10px;
            }
        }

        .partner-offers{
            @extend .row;
            width: 100%;
            padding:20px;

            .offer-item{
                @extend .col-sm-12;
                @extend .col-md-6;
                @extend .col-lg-4;
                margin: 10px;
            }
        }

    }
}

#footer-container{
    display: flex;
    flex-direction: column;
    padding: 20px 0px 0px 0px;
    background-color: $bleu_fonce_THCC!important;
    color: $or_THCC;
    margin-top: 50px;
    font-family: $avenir-next-lt-pro-family;
    font-size: larger;
    //border: red solid 2px;

    #footer-top{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        padding-top: 10px;
        //border: orange solid 2px;

        .static{
            background-color: $or_THCC;                     
        }

        .dynamic{
            background-color: $bleu_clair_THCC;
            color: $bleu_fonce_THCC;
            
            &:hover{
                background-color: $or_THCC;
                color:white;
            }
        }

        div{
            color: white;
            width: 185px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor:pointer;
        }


    }

    #footer-body{
        padding-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        //border: greenyellow solid 2px;

        #site-map{
            cursor:pointer;
        }

        #company-places{
            color:white;
            display: flex;
            flex-direction: column;

            #phone-group,
            #mail-group,
            #office-group{
                display: flex;
                flex-direction: column;
                padding-bottom: 15px;
            }
        }
    }

    #footer-bottom{
        //border: pink solid 1px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        div{
            width: 100%;
            text-align: center;
            height: 90px;
            padding-top: 15px;
            font-size: smaller;
        }

        #social-networks{
            background-color: $or_THCC;
            color: white; 
            display: flex;  
            flex-direction: row;
            justify-content: space-evenly;

            .networks-logo{
                width: 27px;
                height: 27px;
                background-size: cover;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            
            #linked-in{
                @extend .networks-logo;
                background-image: url("../../Assets/img/footer/linkedin.webp");
            }
            
            #facebook{
                @extend .networks-logo;
                background-image: url("../../Assets/img/footer/facebook.webp");
            }
            
            #youtube{
                @extend .networks-logo;
                background-image: url("../../Assets/img/footer/youtube.webp");
            }
        }

        #footer-copyrights{
            background-color: white;
            color: black;
        }
    }

    .no-bullets{
        list-style-type: none;
    }

    .footer-logo{
        background-position: left;
        height: 130px;
        /* max-height: 436px; */
        /* width: 10%; */
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../Assets/img/long_logo.png");
    }
}

/* -------------------- Input form CSS fixes ----------------------------*/
.input-form .col-form-label{
    font-weight: normal;
}
.editor-container{
    //background-color: orange;
    height: 226px;
    margin-bottom: 10px;
    overflow: hidden;
}

.form-error {
    color:$red_error_THCC!important;
    font-style: italic;

    /* width: 100%; */
    text-align: right;
    display: inline;
    padding: 0px 2px;
}

input.PhoneInputInput {
    height: 38px;
    border: none;
}

textarea, input {
    border-bottom: $bleu_fonce_THCC solid 2px!important;
    border-radius: 0!important;
    background-color: $gris_clair_THCC;
    font-family: $input-font-family!important;

    &:hover{
        background-color: $gris_medium_THCC!important;
    }
}

textarea{
    width: 100%!important;
}

.btn-primary {
    background-color: $bleu_fonce_THCC!important;
    border-radius: 0px!important;
    color: $text_color_on_button_THCC!important;
    transition: ease 0.5s;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover{
        background-color: gray!important;        
    }
}

.btn-secondary {
    color: $gris_fonce_THCC;
    background-color: $gris_clair_THCC!important;
    border: none!important;
    border-radius: 0px!important;
    border-bottom: solid 2px $bleu_fonce_THCC!important;
    color: gray!important;

    &:hover{
        background-color: $gris_medium_THCC!important;
    }
}
/*
.btn-secondary:hover {
    border: none;
    background: #fafafa;
    color: gray;
    border-bottom: solid 2px$bleu_fonce_THCC;
}*/

button.dropdown-item,
.btn-secondary span {
    font-family: $input-font-family!important;
}

/* -------------------- Sticky menu bar ----------------------------*/
.sticky-absolute-top {
    top: 0px;
    right: 0px;
    left: 0px;
    position: fixed!important;
    z-index:1000;
}

.sticky-fixed-top {
    top: -40px;
    transform: translateY(40px);
    transition: transform .3s;
    position: sticky!important;
    z-index:1000;
}

#navbar_top{
    background-color: #090426!important;
    box-shadow:0px 2px 5px 0px rgb(0 0 0 / 30%);
    font-family: $menu-font-family!important;
    font-weight: 300;

    .app-title {
        font-size: small;
        padding: 0px;
        margin: 0px;
    }

    .app-logo{
        background-image: url("../../Assets/img/long_logo.png");
        background-position: center;
        background-size: cover;
        height: 30px;
        width: 108px;
        margin: 0px;
        padding: 0px;
    }
}

.navbar-dark .navbar-nav .nav-link{
    color: #B3A357!important;
    font-family: $menu-font-family!important;
    font-weight: 300;
    font-size: smaller;

    &:hover{
        color: white!important;
    }

}

#main_nav{
    transition: ease 1s;
}

/* ---------------------------- Offers ---------------------------------*/
.offers-container{
    .offers-container-header{
        @extend h5;
        color: #0088ff;
        &::before{
            content:fa-content($fa-var-wifi);
            margin-right: 5px;
        }
    }

    .add-offer-toolbar{
        width: 100%;
        background-color: #e8f1fc;
        Button{
            background-color: $bleu_fonce_THCC;
            color: $or_THCC;
            text-align: center;
            width: 100%;;
        }
    }

    .offer-item-container{
        border-top: dashed 1px $bleu_fonce_THCC;
        padding: 10px;
        background-color: aliceblue;

        .offer-item-header{
            display: flex;
            flex-direction: row;
            align-content: stretch;
            justify-content: space-between;
            align-items: baseline;

            .offer-item-title{
                @extend h6;
                color: $bleu_fonce_THCC;
                &::before{
                    content:fa-content($fa-var-wifi);
                    margin-right: 5px;
                }
            }

            .offer-item-delete{
                background-color: pink;
            }
        }
    }
}

/* ---------------------------- Gallery ---------------------------------*/
.gallery-container{
    .gallery-container-header{
        @extend h5;
        color: #0088ff;
        &::before{
            content:fa-content($fa-var-wifi);
            margin-right: 5px;
        }
    }

    .add-gallery-toolbar{
        width: 100%;
        background-color: #e8f1fc;
        Button{
            background-color: $bleu_fonce_THCC;
            color: $or_THCC;
            text-align: center;
            width: 100%;;
        }
    }

    .gallery-item-container{
        border-top: dashed 1px $bleu_fonce_THCC;
        padding: 10px;
        background-color:aliceblue;

        .gallery-item-header{
            display: flex;
            flex-direction: row;
            align-content: stretch;
            justify-content: space-between;
            align-items: baseline;

            .gallery-item-title{
                @extend h6;
                color: $bleu_fonce_THCC;
                &::before{
                    content:fa-content($fa-var-wifi);
                    margin-right: 5px;
                }
            }

            .gallery-item-delete{
                background-color: pink;
            }
        }

        input[type="text"]{
            height: 40px;
            border-top: none;
            border-right: none;
            border-left: none;
            width: 100%;
            font-family: $input-font-family!important;
            font-size: initial;
        }
    }
}
