/**
Items rules 
*/
.low-involve{
    opacity: 0.1;
}

.medium-involve{
    opacity: 0.5;
}

.high-involve{
    opacity: 1;
}

/**
Results rules
*/
.lQueryHeader__input_container {
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    background: transparent;
    position: relative;
    margin-top: 10px;
    box-sizing: border-box;
}

.lQueryHeader__input_container>button::before {
    width: 1em;
    height: 1em;
    border-radius: 1em;
    transform: translate(-50%,-50%);
    border: 2px solid;
}

.lQueryHeader__input_container>button::before, 
.lQueryHeader__input_container>button::after {
    content: "";
    display: block;
    position: absolute;
    top: 45%;
    left: 45%;
}

.lQueryHeader .lQueryHeader__input_container>button::after {
    width: 2px;
    height: 1em;
    transform-origin: 0 0;
    transform: rotate(-45deg) translate(-0.5px,0.5em);
    background-color: white;
}

#header #queryinput {
    width: 100%;
    font-size: 20px;
    background: 0;
    border: 0;
    color: #000;
    /*height: 30px;*/
    height: 36px;
    top: 0;
    left: 0;
    position: absolute;
    padding: 3px 0 2px 0;

    left: -10px;
    background-color: white!important;
}

.wide_in_main {
    margin-left: -17px!important;
    margin-right: -17px!important;
    padding-left: 17px!important;
    padding-right: 17px!important;
}

body, #header, #header input {
    font-family: Avenir Next,Roboto Condensed,Arial,sans-serif;
}

#header>form {
    display: block;
}

.lQueryHeader {
    position: relative;
    margin: 0;
    z-index: 3;
}

.lQueryHeader__input_container>button {
    background-color: #3f556b;
    color: white;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 0;
    width: 44px;
    position: absolute;
    top: -1px;
    bottom: -1px;
    /*height: 45px;*/
    height: auto;
    right: -1px;
    cursor: pointer;
}

button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}

.lQueryHeader .lQueryHeader__input_container>input {
    position: relative!important;
    background: none!important;
    border: none!important;
    color: #000!important;
    padding: 5px 6px 5px 10px!important;
    /*width: calc(100% - 60px)!important;*/
    width: calc(100% - 30px)!important;
}

input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
}

/*--- Results list */
#autocompletion {
    padding: 0;
    position: relative;
    top: -1px;
    margin-right: -1px;
    z-index: 3;
    background-color: #fff;
    border: 1px solid #aaa;
    -webkit-box-shadow: 0 2px 3px -1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0 2px 3px -1px rgba(0,0,0,0.5);
    box-shadow: 0 2px 3px -1px rgba(0,0,0,0.5);
    width: calc(100% - 43px);/*PM Patch*/
}

.autocompletion .autocompletion_item {
    border-bottom: 1px solid #aaa;
    padding: 5px 5px 5px 10px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    background-color: #fff;
}

.autocompletion .autocompletion_item .main_item {
    color: #265180;
}

.autocompletion_item:hover {
    background-color: aliceblue;
}

.autocompletion .main_item {
    display: inline;
    margin-right: 10px;
    font-weight: 400;
    font-size: 18px;
}

.autocompletion .translation_row {
    margin-left: 10px;
}

.translation_row.singleline {
    height: 20px;
}

.singleline {
    position: relative;
    padding-right: 20px;
    height: 20px;
    overflow: hidden;
}

.autocompletion .subject-gender {
    display: inline;
    margin-right: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #aaa;
    font-style: italic;
}

.autocompletion .father-thumb-bible-ref {
    display: inline;
    color: #aaa;
    margin-left: 5px;
    margin-right: 3px;
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
}

/*------------
Overlay mask
------------*/
#contentoverlay{
    position: fixed;
    left: 0;
    top: 0px;
    right: 0px;
    height: 100%;
    opacity: 0.2;
    z-index:2;

    background-color: rgba(248,248,248,0.8);
    opacity: 0.9;
    backdrop-filter: blur(1px) grayscale(80%);
}

/*------------------------
Categories Filter buttons
--------------------------*/
.filter_buttons_container{
    text-align: center;
    /*background-color: #fff;*/
}

[data-active|="0"]{
    color: black;
    background-color: aliceblue;
}

[data-active|="1"]{
    background-color: darkblue;
    color: white;
}

.category-button{
    border: darkblue 2px solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;

    font-size: large;
    font-weight: 400;
    padding: 10px 6px;
    margin: 2px;
    cursor: pointer;
    border-radius: 10px;    
}

.category-button:hover{
    background-color: darkblue;
    color: #B3A357;
    transition: ease 0.5s;
    font-style: italic;
}