/*
JLRPT2 - NestedTitlePicture.scss
*/
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.cdnfonts.com/css/avenir-next-lt-pro');
$avenir-next-lt-pro-family: 'Avenir Next LT Pro', sans-serif; 
$desription-color: #7e7e89;

h1, h2, h3, h4, h5 {
    font-family: $avenir-next-lt-pro-family;
}

.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nested-component2{
    font-family: $avenir-next-lt-pro-family;
    position: relative;
    margin: 0px!important;
    padding: 0px!important;

        .nested-container{
            height: 100%;
            width: 100%;
            background-position: center ;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 1px lightgray solid;
            overflow: hidden;
            
            .background-filter{
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover;
                filter:brightness(60%);

                &:hover{
                    filter: brightness(30%);
                    transition: ease 1s;
                }
            }

            .nested-title{
                @extend h1;
                mix-blend-mode:lighten;
                position: absolute;
            }

            .nested-button{
                position:absolute;
                align-items: flex-start;
                left: 20px;
                top: 20px;
                z-index: 300;
                width:calc(100% - 40px);
                overflow: hidden;

                Button{
                    background-color: transparent;
                    color: white;
                    border: white 1px solid;
                    

                    & :hover{
                        color: orange;
                    }
                }
            }

            .nested-logo{
                //mix-blend-mode:lighten;
                position: absolute;
                background-position: center ;
                background-size: cover;
                background-color: whitesmoke;
                background-repeat: no-repeat;
                border-radius: 50%;
                width: 60px;
                height: 60px;
                left: 15px;
                z-index: 200;
            }

            .event-override-image-container{
                align-items: flex-end !important;
                position: absolute;
                z-index: 100;
                color: black;
                height: 50%;
                text-align: left;
                padding: 40px 20px 5px 15px;
                background-color: white;
                cursor: pointer;
                overflow: hidden;
                bottom: 1px;
                width: calc(100% - 2px);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;

                .partner-name{
                    @extend h5;                    
                    text-transform: uppercase; 
                }

                .partner-description{
                    color: $desription-color;
                    @extend .ellipsis;
                }
            }
        }
    }